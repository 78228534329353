<template>
  <div id="join-session">
    <defaultTemplate>
      <div class="pd-x-5 mg-y-3" v-if="badgeEvent">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="is-flex ai-center"
        >
          <el-breadcrumb-item :to="{ path: `/calendar` }">
            <span class="text-all font-12">Calendar</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            ><span class="font-12 text-breadcrumb"
              >{{ badgeEvent.courseId }}.{{ badgeEvent.skillType }}
            </span></el-breadcrumb-item
          >
        </el-breadcrumb>
        <badgeDetail />
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import badgeDetail from "@/components/BadgeDetail";
import { HTTP } from "@/service/axios";
export default {
  name: "createRoom",
  props: ["getBadge"],
  computed: {
    user() {
      return this.$store.state.user;
    },
    EventById() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getAllBadge();
    this.getProfile();
  },
  components: {
    defaultTemplate,
    badgeDetail
  },
  data() {
    return {
      confirmDialog: false,
      basedUrl: process.env.VUE_APP_BASE_URL,
      sharedSecret: process.env.VUE_APP_BBB_SHARED_SECRET,
      bbbUrl: process.env.VUE_APP_BBB_BASE_URL,
      regisCourse: 0,
      // 0 คือ เลยวีนที่ปัจจุบัน 1 ลงทะเบียนได้ไม่เกินวัน 2 ลงทะเบียนแล้ว
      status: false,
      // true ลงทะเบียนแล้วคลิกลิงค์  false ไม่ได้ลงทะเบียน
      checkStep: true,
      badgeEvent: null,
      resultJson: null,
      xmlRes: null,
      getProfiledata: null
    };
  },
  methods: {
    getAllBadge() {
      HTTP.get(`api/badge/${this.$route.params.id}`)
        .then(res => {
          if ( res.data.success )
          {
            this.badgeEvent = res.data.obj
           
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    getProfile() {
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`profile`)
        .then(res => {
          if (res.data.success) {
            this.getProfiledata = res.data.obj;
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    }
  }
};
</script>
